.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #f2f2f2;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-footer {
    background-color: #f2f2f2;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    color: #4d4d4d;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 24px;
    align-items: center;
}

.Menu img {
    height: 28px;
    opacity: 0.5;
}

.Footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.Footer a {
    font-size: 0.33em;
    text-decoration: none;
    color: #5b5b5b;
}

.Column {
    display: flex;
    flex-direction: column;
}

.Menu a {
    font-size: 0.5em;
    text-decoration: none;
    color: #5b5b5b;
    margin: 16px;
    font-weight: 400;
}

.Menu a.active {
    color: #484848;
    font-weight: 600;
}

.Page {
    margin: 16px;
}

.Paragraph {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

@media only screen and (max-width: 960px) {
    .Paragraph {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
    }
}

.Paragraph p {
    margin-block-start: 0;
    margin-block-end: 0;
}
.Paragraph img {
    display: block;
    max-width: 100%;
    object-fit: cover;
}

h1 {
    text-align: left;
}

h2 {
    text-align: left;
}

p {
    text-align: left;
}

li {
    list-style: none;
    text-align: left;
}